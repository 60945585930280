import React, { FC, useCallback, useEffect, useRef } from "react";
// @ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
// @ts-ignore
import ClassicEditor from "ckeditor5-build-classic-plus";
import { getHeaders } from "../../../interceptor/axiosInstance";
import Error from "../Error";
import "./editor.scss";
import { boolean } from "yup";

interface EditorProps {
  onChangeHandler: (content: string) => void;
  value: string;
  error?: string;
  readOnly?: boolean;
}

const headers = getHeaders();

const Editor: FC<EditorProps> = ({
  value,
  onChangeHandler,
  error,
  readOnly,
}) => {
  let timer: ReturnType<typeof setTimeout>;
  const pasteWordRef = useRef(false); // Global within your component

  const convertBulletPointsToList = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const paragraphs = Array.from(doc.querySelectorAll("p"));

    let ul = null;
    let ol = null;

    for (const p of paragraphs) {
      const rawText = p.textContent?.trim() || "";

      // Match a single character bullet (letter/symbol) followed by spaces or &nbsp;
      if (/^[a-zA-Z·•\-o0](\s|&nbsp;|\u00a0)+/i.test(rawText)) {
        const liContent = p.innerHTML
          .replace(/^[a-zA-Z·•\-o0](\s|&nbsp;|\u00a0)+/i, "")
          .trim();

        if (!ul && p.parentNode) {
          ul = doc.createElement("ul");
          p.parentNode.insertBefore(ul, p);
        }

        const li = doc.createElement("li");
        li.innerHTML = liContent;
        if (ul) ul.appendChild(li);
        p.remove();
        ol = null;
        continue;
      }

      // Match ordered list bullets: 1. 2. etc
      const orderedMatch = rawText.match(/^\d+\.(\s|&nbsp;|\u00a0)+/);
      if (orderedMatch) {
        const liContent = p.innerHTML
          .replace(/^\d+\.(\s|&nbsp;|\u00a0)+/, "")
          .trim();

        if (!ol && p.parentNode) {
          ol = doc.createElement("ol");
          p.parentNode.insertBefore(ol, p);
        }

        const li = doc.createElement("li");
        li.innerHTML = liContent;
        if (ol) ol.appendChild(li);
        p.remove();
        ul = null;
        continue;
      }

      // Reset
      ul = null;
      ol = null;
    }

    return doc.body.innerHTML;
  };

  const cleanOfficeHtml = (html: string) => {
    const cleaned = html
      // Remove Office/Word namespace tags and metadata
      .replace(/<\?xml[^>]*>/gi, "")
      .replace(/<!--\[if.*?endif\]-->/gi, "")
      // .replace(/<style[^>]*>[\s\S]*?<\/style>/gi, "")
      .replace(/<o:[^>]*>[\s\S]*?<\/o:[^>]*>/gi, "")
      .replace(/<(\/)?(w|o|v|m):[^>]*>/gi, "")
      .replace(/<meta[^>]*>/gi, "")
      .replace(/<link[^>]*>/gi, "")
      .trim();
    return cleaned;
  };

  const removeBlankLines = (html: string) => {
    return html
      .replace(/\u00A0/g, " ") // Convert non-breaking spaces to regular spaces
      .replace(/<p[^>]*>(\s|&nbsp;)*<\/p>/gi, "") // Remove empty or whitespace-only <p> tags
      .replace(/\n{2,}/g, "\n") // Normalize excessive newlines if they occur
      .trim();
  };

  return (
    <div className="editor">
      <CKEditor
        editor={ClassicEditor}
        data={value}
        config={{
          simpleUpload: {
            uploadUrl: "https://google.com/test",
            headers: headers,
          },
          isReadOnly: !!readOnly,
          clipboard: {
            // Preserve formatting when pasting from MS Office
            contentTransformation: [],
          },
          pasteFromOffice: {
            // Allows pasting Word content with formatting
            preserveHtmlStructure: true,
            styles: true,
          },
          // plugins: [...ClassicEditor.builtinPlugins],
        }}
        onReady={(editor: any) => {
          editor.editing.view.document.on("paste", (evt: any, data: any) => {
            const html = data.dataTransfer.getData("text/html");
            if (
              html &&
              html.includes("urn:schemas-microsoft-com:office:word")
            ) {
              evt.stop();
              pasteWordRef.current = true;
              const cleanedHtml = cleanOfficeHtml(html);
              const viewFragment = editor.data.processor.toView(cleanedHtml);
              const modelFragment = editor.data.toModel(viewFragment);
              editor.model.insertContent(modelFragment);
            } else {
              pasteWordRef.current = false;
            }
          });
        }}
        onChange={(_: any, editor: any) => {
          clearTimeout(timer);
          timer = setTimeout(() => {
            try {
              if (pasteWordRef.current) {
                const raw = editor.getData();
                const parsed = removeBlankLines(raw);
                const formatted = convertBulletPointsToList(parsed);
                pasteWordRef.current = false;
                onChangeHandler(formatted);
              } else {
                // regular onChange logic (if any)
                onChangeHandler(editor.getData());
              }
            } catch (e) {
              console.error("CKEditor error:", e);
              onChangeHandler("");
            }
          }, 100);
        }}
        onFileUploadRequest
      />
      <Error message={error ?? ""} />
    </div>
  );
};

export default Editor;
